<script setup lang="ts">
  const questionsEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    questionsEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'questions');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div ref="questionsEl" class="questions-with-resources main-container">
    <div id="questions" data-nav="Популярные вопросы" class="questions-title scroll-normalize">
      Популярные вопросы
    </div>
    <div class="questions-container">
      <slot></slot>
    </div>
    <div class="resources-container">
      <span>Список литературы:</span>
      <slot name="resources"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
.questions-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;

  color: #262633;

  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.questions-with-resources {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  @media (max-width: 1023px) {
    padding: 30px 20px;
  }
}
.questions-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 80px;
}
.resources-container {
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  & * {
    color: #878FA2 !important;
  }
}
li {
  list-style-type: circle;
  list-style-position: inside;
  color: #878FA2;
}
</style>
